<template>
  <div>
    <v-form>
      <p class="mb-5">
        When you are finished your experiments, you may have produced products or have leftover
        reagents which must be disposed of in the appropriate chemical waste container. The list of
        substances below are ones that you will use or produce in your chemistry labs. From the list
        available, select the appropriate place to put each substance once it is waste:
      </p>

      <p v-for="question in questions" :key="'pt-1-' + question.inputValue">
        <chemical-latex :content="question.text" />
        <v-select
          v-model="inputs[question.inputValue]"
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
          class="mb-0"
          style="width: 250px"
        >
          <template #item="{item}">
            <stemble-latex :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemMcMQuiz5_Q6',
  components: {ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        inputNaCl: null,
        inputAcetone: null,
        inputCu: null,
        inputBro: null,
        inputHCl: null,
        inputNaOH: null,
      },
      questions: [
        {
          text: 'a) NaCl(aq)',
          inputValue: 'inputNaCl',
        },
        {
          text: 'b) acetone(l)',
          inputValue: 'inputAcetone',
        },
        {
          text: 'c) Cu(s)',
          inputValue: 'inputCu',
        },
        {
          text: 'd) bromoacetone(l)',
          inputValue: 'inputBro',
        },
        {
          text: 'e) HCl(aq)',
          inputValue: 'inputHCl',
        },
        {
          text: 'f) NaOH(aq)',
          inputValue: 'inputNaOH',
        },
      ],
      options: [
        {text: 'Inorganic acids and salts', value: 'inorgAcidsSalts'},
        {text: 'Inorganic bases', value: 'inorgBases'},
        {text: 'Solid waste', value: 'solidWaste'},
        {text: 'Organics', value: 'organics'},
        {text: 'Halogenated Organics', value: 'halogenatedOrganics'},
        {text: 'Pour down the sink', value: 'sink'},
        {text: 'Garbage pail', value: 'garbage'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
